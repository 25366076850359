<template>
  <contracts-form v-if="displayForm" :key="$route.fullPath" v-model="obItem" />
</template>

<script lang="ts">
import { Component, Mixins, Provide } from "vue-property-decorator";
import ContractsMixin from "@/modules/contracts/mixins/ContractsMixin";
import ContractsForm from "@/modules/contracts/components/Form.vue";
import type { Contract, ContractData } from "@planetadeleste/vue-mc-gw";
import {
  ContractPosition,
  Customer,
  TimePeriod,
} from "@planetadeleste/vue-mc-gw";
import { get, map, omit, set } from "lodash";
import { ref } from "@/services/Utils";

@Component({
  components: { ContractsForm },
})
export default class Form extends Mixins(ContractsMixin) {
  @Provide() contract = ref<Contract | null>(null);

  onMounted() {
    if (this.$route.params?.id) {
      this.hideForm();
    }
  }

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.after.fetch`, (obData: ContractData) => {
      // Set positions as ContractPosition model collection
      const arPositions = map(get(obData, "positions", []), (obPosition) =>
        obPosition instanceof ContractPosition
          ? obPosition
          : new ContractPosition(obPosition)
      );

      // Set period as TimePeriod model
      const obPeriod =
        obData.period instanceof TimePeriod
          ? obData.period
          : new TimePeriod(obData.period);

      // Set customer as Customer model
      const obCustomer =
        obData.customer instanceof Customer
          ? obData.customer
          : new Customer(obData.customer);

      set(obData, "positions", arPositions);
      set(obData, "period", obPeriod);
      set(obData, "customer", obCustomer);

      this.obItem.set(obData);
      this.contract.value = this.obItem as Contract;
    });

    this.addEvent(`${this.sModelName}.after.create`, () => {
      this.contract.value = this.obItem as Contract;
    });

    this.addEvent(`${this.sModelName}.before.sync`, (obModel: Contract) => {
      const arPositions = map(obModel.get("positions", []), (obPosition) => {
        const obPositionData =
          obPosition instanceof ContractPosition
            ? obPosition.attributes
            : obPosition;

        return omit(obPositionData, [
          "product",
          "currency",
          "movement_type",
          "payment_term",
          "tax_type",
        ]);
      });

      const obPeriod = obModel.get("period");
      const obPeridData =
        obPeriod instanceof TimePeriod ? obPeriod.attributes : obPeriod;

      obModel.set("positions", arPositions);
      obModel.set("period", obPeridData);
      obModel.unset("customer");
    });
  }
}
</script>
